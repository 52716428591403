import React from "react"

import Layout from '../layouts/default'

export default () => (
    <Layout title="Sprachauswahl" locale="de">
      <ul>
        <li><a href="/de/">DE</a></li>
        <li><a href="/en/">EN</a></li>
      </ul>
    </Layout>
)
